<template>
  <div class="relative min-h-screen">
    <div class="pb-60">
      <nav-bar/>
      <router-view/>
    </div>
    <Footer class="absolute bottom-0 w-full"/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer
  }
}
</script>

<style>
body {
  background-color: rgba(17, 24, 39)
}
</style>
