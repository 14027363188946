<template>
    <div class="w-full text-center flex flex-col justify-center items-center">
        <h1 class="text-5xl font-bold gradient mt-6">Els nostres productes</h1>
        <div class="grid grid-rows-4 grid-container w-4/5 mt-5">
            <producte-card :imagenNombre="'barrapan.jpeg'" :nombre="'Barra de pa rústica'" />
            <producte-card :imagenNombre="'barrapanrustica.jpeg'" :nombre="'Pa de poble'"/>
            <producte-card :imagenNombre="'cocamigas.jpeg'" :nombre="'Coca ojaldrada en mantega'"/>
            <producte-card :imagenNombre="'cruassan.jpeg'" :nombre="'Croissant de mantequilla'"/>
            <producte-card :imagenNombre="'cruassanraro.jpeg'" :nombre="'Mini caracola de chocolate'"/>
            <producte-card :imagenNombre="'empanadillas.jpeg'" :nombre="'Mini empanades de tomata'"/>
            <producte-card :imagenNombre="'enseimada.jpeg'" :nombre="'Ensaimada'"/>
            <producte-card :imagenNombre="'enseimadachoco.jpeg'" :nombre="'Napolitana de chocolate'"/>
            <producte-card :imagenNombre="'napolitana.jpeg'" :nombre="'Napolitana de york i formatge'"/>            
        </div>
    </div>
</template>

<script>
import ProducteCard from "../components/ProducteCard.vue"

export default { 
    components: { ProducteCard },
    name: 'Productes'
}
</script>

<style scoped>
    .gradient {
        background: linear-gradient(to right, rgba(49, 46, 129), rgba(99, 102, 241), rgba(129, 140, 248));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .grid-container{
        grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    }
</style>