<template>
    <footer class="p-4 border-t-4 border-gray-900">
        <div class="mx-auto max-w-screen text-center text-gray-400 flex flex-col space-y-2">
            <div class="flex justify-center space-x-2">
                <PhoneIcon class="h-6"/>
                <p>747 469 101</p>
            </div>
            <div class="flex justify-center space-x-2">
                <AtSymbolIcon class="h-6"/>
                <p>luima0136@gmail.com</p>
            </div>
            <div class="flex justify-center space-x-2">
                <MapIcon class="h-6"/>
                <p>Exconvento 3 Mercat - Monòver 03640</p>
            </div>
        </div>
    </footer>
</template>

<script>
import {PhoneIcon, AtSymbolIcon, MapIcon} from '@heroicons/vue/outline'

export default {
    name: 'Footer',
    components: {
        PhoneIcon,
        AtSymbolIcon,
        MapIcon
    }
}
</script>