<template>
    <div class="w-full bg-indigo-900 py-4 px-2 gradient">
        <router-link :to="{name: 'Home'}" class="flex space-x-2 items-center ">
            <img alt="Icono app" src="./../../public/icono.png" class="h-20 ml-5"/>
            <p class="text-4xl font-bold text-gray-200 mb-2">Rico i Verdú</p>
            <p class="text-2xl text-gray-300">Despatx de pa i dolços</p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
}
</script>

<style scoped>
    .gradient {
        background-image: linear-gradient(to right, rgba(49, 46, 129), rgba(99, 102, 241), rgba(129, 140, 248));
    }
</style>