<template>
    <div class="border-4 border-indigo-600 rounded-md pt-6 pb-4 m-4 flex flex-col items-center">
        <img :src="require(`./../assets/productos/${imagenNombre}`)" class="rounded-xl w-full max-h-80 px-8"/>
        <p class="text-xl font-bold text-indigo-300 mt-4">{{ nombre }}</p>
        <p class="text-lg font-semibold text-indigo-200 mt-2">{{ descripcion }}</p>   
    </div>
</template>

<script>
export default {
    name: 'ProducteCard',
    props: {
        nombre: String,
        descripcion: String,
        imagenNombre: String
    },
    data(){
        return {
            carpetaUrl: './../assets/productos/'
        }
    }
}
</script>