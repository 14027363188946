import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Productes from '../views/Productes.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/productes',
    name: 'Productes',
    component: Productes
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
